import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Post from '../components/Post'
import "katex/dist/katex.min.css"
import { GlobalStyle } from '../util/styles'
import {Ptd, Ptd2} from '../components/PageTitle/styles'

const tagPosts = ({ data}) => {
  const { totalCount } = data.allMdx
  const pageHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } `


  return (
    <>
    <Ptd2 >
          </Ptd2>
    <Layout pageTitle={pageHeader}>
      <GlobalStyle></GlobalStyle>
      {data.allMdx.edges.map(({ node }) => (
        <Post
          key={node.id}
          slug={'/' + node.fields.slug}
          title={node.frontmatter.title}
          author={node.frontmatter.author}
          date={node.frontmatter.date}
          body={node.excerpt}
          tags={node.frontmatter.tags}
          fluid={node.frontmatter.image.childImageSharp.fluid}
        />
      ))}
    </Layout>
    </>
  )
}

export const tagQuery = graphql`
  query($tag: String!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM Do YYYY")
            author
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 650, maxHeight: 371) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default tagPosts
